import React, {Suspense} from 'react';
import Layout from './components/Layout/';
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom';

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/red.css';

// Include Routes
import routes from './routes';

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>

    }
  };
}

function App () {

 const Loader = () => {
    return (
        <div id="preloader">
            <div id="status">
                {/*<MyLoader/>*/}
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );
}

    return (
      <React.Fragment>
      <Router>
        <Suspense fallback = {Loader()} >
            <Switch>
            {routes.map((route, idx) =>
                route.isWithoutLayout ?
                  <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                :
                  <Route path={route.path} exact component={withLayout(route.component)} key={idx} />
            )}
            </Switch>
            </Suspense>
        </Router>
      </React.Fragment>
    );
}

export default withRouter(App);