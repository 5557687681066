import React from 'react';

// Root Include
//const Root = React.lazy(() => import('./pages/Home/indexRoot'));

//Main Index
//const Main = React.lazy(() => import('./pages/Home/indexMain'));

//Special
//const PageComingSoon = React.lazy(() => import('./pages/Pages/Special/PageComingSoon'));
//const PageComingSoon2 = React.lazy(() => import('./pages/Pages/Special/PageComingSoon2'));
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
//const PageMaintenance = React.lazy(() => import('./pages/Pages/Special/PageMaintenance'));

// Import all components
const Business = React.lazy(() => import('./pages/Business/index'));
//Docs

//const PageAboutUs = React.lazy(() => import('./pages/Pages/PageAboutUs'));
//const PagePricing = React.lazy(() => import('./pages/Pages/PagePricing'));
const PageServices = React.lazy(() => import('./pages/Pages/PageServices'));
//const PageTeam = React.lazy(() => import('./pages/Pages/PageTeam'));

//Account

//Career
//const PageJobsSidebar = React.lazy(() => import('./pages/Pages/Careers/PageJobsSidebar'));
//const PageJobDetail = React.lazy(() => import('./pages/Pages/Careers/PageJobDetail'));
//const PageJob = React.lazy(() => import('./pages/Pages/Careers/PageJob'));
const PageJobApply = React.lazy(() => import('./pages/Pages/Careers/PageJobApply'));
//const PageJobCompany = React.lazy(() => import('./pages/Pages/Careers/PageJobCompany'));
//const PageJobCandidate = React.lazy(() => import('./pages/Pages/Careers/PageJobCandidate'));

//Blog

//Work
const PageWorkModern = React.lazy(() => import('./pages/Pages/Work/PageWorkModern'));
const PageWorkDetail = React.lazy(() => import('./pages/Pages/Work/PageWorkDetail'));
const PageWorkClassic = React.lazy(() => import('./pages/Pages/Work/PageWorkClassic'));
const PageWorkGrid = React.lazy(() => import('./pages/Pages/Work/PageWorkGrid'));
const PageWorkMasonry = React.lazy(() => import('./pages/Pages/Work/PageWorkMasonry'));

//Utility
const PagePrivacy = React.lazy(() => import('./pages/Pages/Utility/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

//Contact
//const PageContactDetail = React.lazy(() => import('./pages/Pages/Contact/PageContactDetail'));
const PageContactOne = React.lazy(() => import('./pages/Pages/Contact/PageContactOne'));
//const PageContactThree = React.lazy(() => import('./pages/Pages/Contact/PageContactThree'));
//const PageContactTwo = React.lazy(() => import('./pages/Pages/Contact/PageContactTwo'));

//Email

//Help Center

//Shop

const routes = [
    //routes without Layout

    //Contct without layout
    //    { path: '/page-contact-detail', component: PageContactDetail, isWithoutLayout : true },

    //Email Pages
    //Special Pages
    { path: '/page-error', component: PageError, isWithoutLayout: true },

    //User Pages
    // Landings

    { path: '/index-business', component: Business },

    ///{ path: '/about', component: PageAboutUs },
    // { path: '/plans', component: PagePricing },
    { path: '/services', component: PageServices },
    //{ path: '/page-team', component: PageTeam },

    //Help Center

    //Shop

    //Utility
    { path: '/terms-conditions', component: PageTerms },
    // { path: '/privacy-policy', component: PagePrivacy },
    { path: '/returns-policy', component: PagePrivacy },

    //Page Work
    { path: '/page-work-modern', component: PageWorkModern },
    { path: '/page-work-detail', component: PageWorkDetail },
    { path: '/page-work-classic', component: PageWorkClassic },
    { path: '/page-work-grid', component: PageWorkGrid },
    { path: '/page-work-masonry', component: PageWorkMasonry },

    //Page Profile

    //Page Job
    { path: '/careers', component: PageJobApply },

    //Page Blog

    //Page Contact
    { path: '/contact', component: PageContactOne },
    // { path: '/page-contact-three', component: PageContactThree },
    //  { path: '/page-contact-two', component: PageContactTwo },

    //Docs

    //Index Main
    { path: '/', component: Business },

    //Index root

    { path: '/', component: Business, isWithoutLayout: true, exact: true },
    { component: PageError, isWithoutLayout: true, exact: false },

];

export default routes;