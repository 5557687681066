import React, { Suspense} from 'react';
import {withRouter} from 'react-router-dom';

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
//import ThemeSwitcher from "./ThemeSwitcher";

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Layout Components
const Topbar = React.lazy(() => import('./Topbar'));
const NavbarPage = React.lazy(() => import('../../pages/Saas Onepage/NavbarPage'));
const Footer = React.lazy(() => import('./Footer'));

const CustomDot = () => {
    return (
        <i><FeatherIcon icon="arrow-up" className="icons"/></i>
    );
};

function Layout(props) {

    const Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    {/*<MyLoader/>*/}
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Suspense fallback={Loader()}>
                {
                    props.location.pathname === "/index-onepage" ?
                        <NavbarPage/>
                        :
                        <Topbar/>
                }

                {props.children}
                {(() => {

                    return (
                        <Footer/>
                    )

                })()}


                {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
                {/* scrollup button */}
                <ScrollUpButton ContainerClassName="classForContainer" style={{height: 36, width: 36}}
                                TransitionClassName="classForTransition">
                    <CustomDot/>
                </ScrollUpButton>
                {/* </div> */}

                {/* theme switcher */}
            </Suspense>
        </React.Fragment>
    );
}

export default withRouter(Layout);
